<!-- 改价记录 -->
<template>
  <section class="cont changeRecord">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>房价设置</el-breadcrumb-item>
        <el-breadcrumb-item>改价记录</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <label class="tips">操作日期：</label>
          <el-date-picker v-model="operatorDate" @change="changeSelect" :picker-options="pickerOptions" style="margin-bottom: 20px;" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="width-250"></el-date-picker>
          <label class="tips">价格日期：</label>
          <el-date-picker v-model="priceDate" @change="changeSelect" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="width-250"></el-date-picker>
          <el-select v-model="status" class="tips" @change="changeSelect">
            <el-option label="全部状态" value=""></el-option>
            <el-option label="成功" value="1"></el-option>
            <el-option label="失败" value="0"></el-option>
          </el-select>
          <el-select v-model="orderSource" class="tips" @change="changeSelect">
            <el-option label="全部渠道" value=""></el-option>
            <el-option :label="item.label" :value="item.value" v-for="item in channel" :key="item.value"></el-option>
          </el-select>
          <el-select v-model="roomTypeId" class="tips" @change="changeSelect">
            <el-option label="全部房型" value=""></el-option>
            <el-option v-for="item in roomTypeList" :key="item.id" :label="item.roomTypeName" :value="item.id"></el-option>
          </el-select>
          <el-input v-model="operatorUserName" placeholder="请输入操作人" class="width-250 tips"></el-input>
          <el-row class="right-btn">
            <el-button class="bg-gradient" type="primary" @click="dataExport"><span>导出</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" height="531" border fit :stripe="true" v-loading="loading">
          <el-table-column label="渠道" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.pmsSourceType | channelName(that) }}
            </template>
          </el-table-column>
          <el-table-column prop="roomTypeName" label="本地房型" min-width="30"></el-table-column>
          <!-- <el-table-column label="渠道房型" min-width="30"> - </el-table-column> -->
          <el-table-column prop="priceDate" label="价格日期" min-width="30"></el-table-column>
          <el-table-column label="修改前价格" align="right" min-width="30">
            <template slot-scope="scope">
              ￥{{ scope.row.oldRoomPrice }}
            </template>
          </el-table-column>
          <el-table-column label="修改后价格" align="right" min-width="30">
            <template slot-scope="scope">
              ￥{{ scope.row.roomPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="operatorUserName" label="操作人" min-width="30"></el-table-column>
          <el-table-column label="改价状态" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.status==="NO"?"失败":"成功" }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间" min-width="30"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { pms,getChangeRecordExport } from '@/api/interface/pms';
import { exportFile,getDict } from '@/common/js/common'
export default {
  data(){
    return{
      that:this,
      hotelId:'',          // 酒店ID
      roomTypeList:[],     // 房型列表
      channel:[],          // 渠道列表

      operatorDate:"",     // 操作日期
      priceDate:"",        // 价格日期
      status:"",           // 状态
      orderSource:"",      // 渠道
      roomTypeId:"",       // 房型
      operatorUserName:"", // 操作人

      tableData:[],        // 表格数据
      loading: true,       // 表格加载效果
      total: 0,            // 用户列表总条目数
      page: 1,             // 当前页 默认第一页
      limit:10,            // 每页显示数

      // 日历快捷选项配置
      pickerOptions: {
        disabledDate(time) {
            return time.getTime() >= Date.now() ;
        }
      },
    }
  },
  computed:{
    ...mapState(['dictData','hotelInfo']),
  },
  watch:{
    operatorUserName(){
      this.page = 1;
      this.getData();
    },
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getRoomType();
        this.page = 1;
        this.getData();
      }
    }
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    getDict(["order-source"],false);
    this.channel = [...this.dictData['order-source-list']];
    this.getRoomType();
    this.getData();
  },
  methods:{
    // 动态请求
    changeSelect(){
      this.page = 1;
      this.getData();
    },
    // 获取房型列表
    getRoomType(){
      const url = pms.getRoomTypeList;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.roomTypeList = res.records;
        }
      })
    },
    // 获取改价记录列表
    getData(){
      const url = pms.getChangeRecord + `?limit=${this.limit}&page=${this.page}`;
      const param = {
        hotelId:this.hotelId,                                               // 酒店ID
        operatorBeginDate:this.operatorDate===null?"":this.operatorDate[0], // 操作开始日期
        operatorEndDate:this.operatorDate===null?"":this.operatorDate[1],   // 操作结束日期
        priceBeginDate:this.priceDate===null?"":this.priceDate[0],          // 价格开始日期
        priceEndDate:this.priceDate===null?"":this.priceDate[1],            // 价格结束日期
        status:this.status,                                                 // 状态 0 -1
        pmsSourceType:this.orderSource,                                     // 渠道
        roomTypeId:this.roomTypeId,                                         // 房型ID
        operatorUserName:this.operatorUserName,                             // 操作人名称
        roomTypeSource: "PMS"
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.total = res.total;
          this.tableData = res.records;
          this.loading = false;
        }
      })
    },
    // 导出记录
    dataExport(){
      const param = {
        hotelId:this.hotelId,                                               // 酒店ID
        page:this.page,
        limit:this.limit
      }
      if (this.total) {
        getChangeRecordExport(param).then(res=>{
          exportFile(res,"改价记录" );
        })
      } else {
        this.$message({showClose: true,message:"没有数据可以导出",type: 'error'});
      }
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
  },
  filters:{
    channelName(val,that){
      that.dictData['order-source-list'].forEach(item=>{
        if(item.value === val)  val = item.label
      })
      return val
    }
  }
}
</script>

<style lang="scss" scoped>
.changeRecord{
  .tips{
    margin-left: 10px;
  }
}
</style>
